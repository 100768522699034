import type { ApplicationInsights } from "@microsoft/applicationinsights-web";
import {
  ConnectionDisconnectedReason,
  type CameraVideoTrackInitConfig,
  type ConnectionState,
  type IAgoraRTCClient,
  type IAgoraRTCRemoteUser,
  type ICameraVideoTrack,
  type ILocalTrack,
  type ILocalVideoTrack,
  type IMicrophoneAudioTrack,
  type IRemoteDataChannel,
  type IRemoteTrack,
  type IRemoteVideoTrack,
  type MicrophoneAudioTrackInitConfig,
  type NetworkQuality,
  type VideoPlayerConfig,
} from "agora-rtc-sdk-ng";

class HeltiaAgora {
  private rtc: any;
  private client: IAgoraRTCClient;
  private appId: string;
  private channel: string;
  private token: string | null;
  private uid: string | number | null;
  private appInsights: ApplicationInsights;
  public localTracks: ILocalTrack[] = [];

  public remoteAudioTracks: IRemoteTrack[] = [];
  public remoteVideoTracks: IRemoteTrack[] = [];

  public localMicrophones: InputDeviceInfo[] = [];
  public localCameras: InputDeviceInfo[] = [];

  public activeLocalMicrophone: InputDeviceInfo | null = null;
  public activeLocalCamera: InputDeviceInfo | null = null;

  private activeDevicesStateChange:
    | ((searching: boolean, activeDevices: InputDeviceInfo[] | null) => void)
    | null = null;

  constructor(
    agoraObject: any,
    appId: string,
    channel: string,
    token: string | null,
    appInsights: ApplicationInsights,
    uid?: string | number | null,
    activeDevices: InputDeviceInfo[] | null = null
  ) {
    this.appId = appId;
    this.rtc = markRaw(agoraObject);
    this.client = markRaw(this.rtc.createClient({ mode: "rtc", codec: "vp8" }));
    this.channel = channel;
    this.token = token;
    this.appInsights = appInsights;
    this.uid = uid;
    this.setActiveDevices(activeDevices || []);
  }

  setActiveDevices(activeDevices: InputDeviceInfo[]) {
    if (activeDevices.length > 0) {
      this.activeLocalMicrophone = activeDevices.find(
        (device) => device.kind === "audioinput"
      );
      this.activeLocalCamera = activeDevices.find(
        (device) => device.kind === "videoinput"
      );
    }
  }

  onActiveDeviceStateChange(
    callback: (
      searching: boolean,
      activeDevices: InputDeviceInfo[] | null
    ) => void
  ) {
    this.activeDevicesStateChange = callback;
  }

  joinChannel() {
    return new Promise<string | number>(async (resolve, reject) => {
      this.client
        .join(this.appId, this.channel, this.token, this.uid)
        .then((_uid): void => {
          resolve(_uid);
        })
        .catch((err: Error) => {
          this?.appInsights.trackException({ exception: err });

          reject(err);
        });
    });
  }

  leaveChannel() {
    return new Promise<void>((resolve, reject) => {
      this.client
        .leave()
        .then(() => {
          resolve();
        })
        .catch((err: Error) => {
          this?.appInsights.trackException({ exception: err });

          reject(err);
        });
    });
  }

  getChannelName() {
    return this.client.channelName;
  }

  getChannel() {
    return this.channel;
  }

  subscribe(
    user: IAgoraRTCRemoteUser,
    mediaType: "video" | "audio" | "datachannel",
    channelId?: number
  ): Promise<IRemoteTrack | IRemoteDataChannel> {
    return new Promise<IRemoteTrack | IRemoteDataChannel>((resolve, reject) => {
      this.client
        .subscribe(user, mediaType, channelId)
        .then((result) => {
          resolve(result);
        })
        .catch((err: Error) => {
          this?.appInsights.trackException({ exception: err });
          reject(err);
        });
    });
  }

  unsubscribe(
    user: IAgoraRTCRemoteUser,
    mediaType: "video" | "audio" | "datachannel",
    channelId?: number
  ) {
    return new Promise<void>((resolve, reject) => {
      this.client
        .unsubscribe(user, mediaType, channelId)
        .then(() => {
          resolve();
        })
        .catch((err: Error) => {
          this?.appInsights.trackException({ exception: err });
          reject(err);
        });
    });
  }

  publish() {
    return new Promise<void>((resolve, reject) => {
      this.localTracks.forEach(async (track: ILocalTrack) => {
        try {
          if (!track.enabled) {
            await track.setEnabled(true);
            await this.client.publish(track);
            await track.setEnabled(false);
          } else {
            await this.client.publish(track);
          }
        } catch (err) {
          this?.appInsights.trackException({ exception: err });
          reject(err);
        }
      });
      resolve();
    });
  }

  unpublish() {
    return new Promise<void>((resolve, reject) => {
      this.client
        .unpublish(this.localTracks)
        .then(() => {
          resolve();
        })
        .catch((err: Error) => {
          this?.appInsights.trackException({ exception: err });
          reject(err);
        });
    });
  }

  async playLocalTracks(elementId: string, config: VideoPlayerConfig = {}) {
    try {
      await Promise.all(
        this.localTracks.map((track) => {
          if (track.trackMediaType == "video" && !track.isPlaying) {
            (track as ILocalVideoTrack).play(elementId, config);
          }
        })
      );
    } catch (err) {
      this?.appInsights.trackException({ exception: err });
    }
  }

  stopLocalTracks() {
    try {
      this.localTracks.forEach((track) => {
        if (track && track.isPlaying) track.stop();
      });
    } catch (err) {
      this?.appInsights.trackException({ exception: err });
    }
  }

  playRemoteAudioTracks(elementId: string) {
    this.remoteAudioTracks.forEach((track) => {
      track.play(elementId);
    });
  }

  playRemoteVideoTracks(elementId: string, config: VideoPlayerConfig) {
    this.remoteVideoTracks.forEach((track) => {
      (track as IRemoteVideoTrack).play(elementId, config);
    });
  }

  stopRemoteAudioTracks() {
    this.remoteAudioTracks.forEach((track) => {
      track.stop();
    });
  }

  stopRemoteVideoTracks() {
    this.remoteVideoTracks.forEach((track) => {
      track.stop();
    });
  }

  enableVolumeIndicator(
    callback: (
      volume: { uid: string | number; level: number },
      index: number
    ) => void
  ) {
    try {
      this.client.enableAudioVolumeIndicator();
      this.client.on("volume-indicator", callback);
    } catch (err) {
      this?.appInsights.trackException({ exception: err });
    }
  }

  createLocalTracks(
    audioConfig?: MicrophoneAudioTrackInitConfig,
    videoConfig?: CameraVideoTrackInitConfig
  ): Promise<[IMicrophoneAudioTrack, ICameraVideoTrack]> {
    return new Promise<[IMicrophoneAudioTrack, ICameraVideoTrack]>(
      (resolve, reject) => {
        this.rtc
          .createMicrophoneAndCameraTracks(audioConfig, videoConfig)
          .then((tracks) => {
            this.localTracks = tracks;
            resolve(tracks);
          })
          .catch((err: Error) => {
            this?.appInsights.trackException({ exception: err });
            reject(err);
          });
      }
    );
  }

  async getDevices() {
    [this.localMicrophones, this.localCameras] = await Promise.all([
      this.rtc.getMicrophones(),
      this.rtc.getCameras(),
    ]);
  }

  async activateProperLocalTracks() {
    if (this.activeDevicesStateChange) {
      this.activeDevicesStateChange(true, null);
    }

    this.localTracks = await Promise.all([
      this.createProperMicrophoneTrack(),
      this.createProperCameraTrack(),
    ]);

    if (this.activeDevicesStateChange) {
      this.activeDevicesStateChange(false, [
        this.activeLocalMicrophone,
        this.activeLocalCamera,
      ]);
    }
  }

  async createProperMicrophoneTrack() {
    const defaultDevice = this.activeLocalMicrophone?.deviceId
      ? this.localMicrophones.find(
          (mic) => mic.deviceId === this.activeLocalMicrophone.deviceId
        )
      : this.localMicrophones.find((mic) => mic.deviceId === "default");

    if (defaultDevice) {
      const audioTrack = await this.rtc.createMicrophoneAudioTrack({
        microphoneId: defaultDevice.deviceId,
        encoderConfig: "music_standard",
      });
      this.activeLocalMicrophone = defaultDevice;
      return audioTrack;
    }

    let micIndex = 0;
    while (
      this.activeLocalMicrophone == null &&
      this.localMicrophones?.length > 0
    ) {
      try {
        const microphone = this.localMicrophones[micIndex++];
        const audioTrack = await this.rtc.createMicrophoneAudioTrack({
          microphoneId: microphone.deviceId,
          encoderConfig: "music_standard",
        });

        const isAudioTrackActive = await this.rtc.checkAudioTrackIsActive(
          audioTrack
        );

        if (isAudioTrackActive === true) {
          this.activeLocalMicrophone = microphone;
          return audioTrack;
        }
      } catch (err) {
        console.error(err);
      }

      if (micIndex === this.localMicrophones.length) {
        return null;
      }
    }
  }

  async createProperCameraTrack() {
    const defaultDevice = this.activeLocalCamera?.deviceId
      ? this.localCameras.find(
          (cam) => cam.deviceId === this.activeLocalCamera.deviceId
        )
      : this.localCameras.find((cam) => cam.deviceId === "default");

    if (defaultDevice) {
      const videoTrack = await this.rtc.createCameraVideoTrack({
        cameraId: defaultDevice.deviceId,
      });

      this.activeLocalCamera = defaultDevice;
      return videoTrack;
    }

    let camIndex = 0;
    while (this.activeLocalCamera == null && this.localCameras?.length > 0) {
      try {
        const camera = this.localCameras[camIndex++];
        const videoTrack = await this.rtc.createCameraVideoTrack({
          cameraId: camera.deviceId,
        });

        const isVideoTrackActive = await this.rtc.checkVideoTrackIsActive(
          videoTrack
        );

        if (isVideoTrackActive === true) {
          this.activeLocalCamera = camera;
          return videoTrack;
        } else {
          await videoTrack.close();
        }
      } catch (err) {
        console.error(err);
      }

      if (camIndex === this.localCameras.length) {
        return null;
      }
    }
  }

  async closeLocalTracks() {
    try {
      await Promise.all(this.localTracks.map((track) => track.close()));
    } catch (err) {
      this?.appInsights.trackException({ exception: err });
    }
    this.activeLocalMicrophone = null;
    this.activeLocalCamera = null;
  }

  onConnectionStateChange(
    callback: (
      state: ConnectionState,
      revState: ConnectionState,
      reason?: ConnectionDisconnectedReason
    ) => void
  ) {
    try {
      this.client.on("connection-state-change", callback);
    } catch (err) {
      this?.appInsights.trackException({ exception: err });
    }
  }

  onRemoteUserJoined(callback: (user: IAgoraRTCRemoteUser) => void) {
    try {
      this.client.on("user-joined", callback);
    } catch (err) {
      this?.appInsights.trackException({ exception: err });
    }
  }

  onRemoteUserLeft(callback: (user: IAgoraRTCRemoteUser) => void) {
    try {
      this.client.on("user-left", callback);
    } catch (err) {
      this?.appInsights.trackException({ exception: err });
    }
  }

  onRemotePublish(
    callback: (user: IAgoraRTCRemoteUser, trackType: string) => void
  ) {
    try {
      this.client.on("user-published", callback);
    } catch (err) {
      this?.appInsights.trackException({ exception: err });
    }
  }

  onRemoteUnpublish(
    callback: (user: IAgoraRTCRemoteUser, trackType: string) => void
  ) {
    try {
      this.client.on("user-unpublished", callback);
    } catch (err) {
      this?.appInsights.trackException({ exception: err });
    }
  }

  onUserInfoUpdated(callback: (user: IAgoraRTCRemoteUser) => void) {
    try {
      this.client.on("user-info-updated", callback);
    } catch (err) {
      this?.appInsights.trackException({ exception: err });
    }
  }

  onNetworkQualityChange(callback: (NetworkQuality: NetworkQuality) => void) {
    try {
      this.client.on("network-quality", callback);
    } catch (err) {
      this?.appInsights.trackException({ exception: err });
    }
  }

  switchAudioState(enabled: boolean) {
    try {
      var audioTrack = this.localTracks?.find(
        (t) => t.trackMediaType == "audio"
      );

      if (audioTrack) {
        audioTrack.setEnabled(enabled);
      }
    } catch (err) {
      this?.appInsights.trackException({ exception: err });
    }
  }

  switchVideoState(enabled: boolean) {
    try {
      var videoTrack = this.localTracks?.find(
        (t) => t.trackMediaType == "video"
      );
      if (videoTrack) {
        videoTrack.setEnabled(enabled);
      }
    } catch (err) {
      this?.appInsights.trackException({ exception: err });
    }
  }

  switchAudioMute(enabled: boolean) {
    try {
      var audioTrack = this.localTracks?.find(
        (t) => t.trackMediaType == "audio"
      );
      if (audioTrack) {
        audioTrack.setMuted(enabled);
      }
    } catch (err) {
      this?.appInsights.trackException({ exception: err });
    }
  }

  switchVideoMute(enabled: boolean) {
    try {
      var videoTrack = this.localTracks?.find(
        (t) => t.trackMediaType == "video"
      );
      if (videoTrack) {
        videoTrack.setMuted(enabled);
      }
    } catch (err) {
      this?.appInsights.trackException({ exception: err });
    }
  }
}

export default HeltiaAgora;
