import { defineStore } from "pinia";
import { VideoCallPermissions } from "~/model/meeting/videoCall/videoCallPermissions";
import { HeltiaSignalR } from "~/utils/HeltiaSignalR";
import jwt_decode from "jwt-decode";
import HeltiaAgora from "~/utils/HeltiaAgora";
import { useAuthStore } from "../auth";
import type { IRemoteAudioTrack, IRemoteVideoTrack } from "agora-rtc-sdk-ng";
import { AuthenticationResponse } from "~/model/auth/authenticationResponse";
import { useUserStore } from "../user";
import { MeetingDeleteRequest } from "~/model/meeting/deleteRequest";
import { deleteMeeting, getMeetingCount } from "~/helpers/api/meeting";
import { isAfter, isBefore, subDays } from "date-fns";
import Tracking from "~/utils/tracking";

export type MeetingSource = "Mobile" | "Web Page" | "Web App";

export const useMeetingStore = defineStore("meeting", {
  state: () => ({
    videoCallPermissions: Object.assign({}, new VideoCallPermissions()),
    signalR: null as HeltiaSignalR | null,
    signal: {
      status: false as boolean,
      otc: null as string | null,
      countdown: null as number | null,
      bearer: null as string | null,
      userId: null as string | null,
      meetingId: null as string | null,
      meeting: null as any | null,
      user: null as any | null,
    },
    agora: null as HeltiaAgora | null,
    viewState: "preAuthentication" as string,
    approved: false as boolean,
    onOtcAuthenticated: null as Function | null,
    selectedMeeting: null as any | null,
    cancelMeetingForm: {
      cancelReason: "" as string | null,
      inProgress: false as boolean,
    },
    meetingSource: null as MeetingSource | null,
    meetingCounts: {} as Record<string, number>,
    activeDevices: [] as InputDeviceInfo[] | null,
    isActiveDeviceSearching: true as boolean,
  }),
  getters: {
    isUserAudioEnabled: (state) => {
      return state.agora?.localTracks?.find((t) => t.trackMediaType == "audio")
        ?.enabled;
    },
    isUserVideoEnabled: (state) => {
      return state.agora?.localTracks?.find((t) => t.trackMediaType == "video")
        ?.enabled;
    },
    isUserAudioMuted: (state) => {
      return state.agora?.localTracks?.find((t) => t.trackMediaType == "audio")
        ?.muted;
    },
    isUserClient: (state) => {
      return state.signal.user.userType == "Client";
    },
    providerInMeeting: (state) => {
      return state.signal.meeting?.participants?.find(
        (p: any) => p.userType !== "Client"
      );
    },
    clientInMeeting: (state) => {
      return state.signal.meeting?.participants?.find(
        (p: any) => p.userType == "Client"
      );
    },
    isCallPermissionsGranted: (state) => {
      return (
        state.videoCallPermissions.isCameraPermissionGranted &&
        state.videoCallPermissions.isMicrophonePermissionGranted
      );
    },
    isViewstateInAuthentication: (state) => {
      return state.viewState == "inAuthentication";
    },
    isViewstateInLobby: (state) => {
      return state.viewState == "inLobby";
    },
    isViewstateInCall: (state) => {
      return state.viewState == "preCall" || state.viewState == "inCall";
    },
    isViewstateInExpired: (state) => {
      return state.viewState == "expired";
    },
    isViewstateInInsufficientCredits: (state) => {
      return state.viewState == "insufficientCredits";
    },
    isViewStateAlreadyInMeeting: (state) => {
      return state.viewState == "alreadyInMeeting";
    },
    isOtherParticipantInCall: (state) => {
      var otherParticipantInstances =
        state.signal.meeting?.participations?.filter(
          (p: any) =>
            p.userId !== state.signal.userId && p.viewState === "preCall"
        );

      return otherParticipantInstances?.length > 0;
    },

    otherParticipant: (state) => {
      return state.signal.meeting?.participants?.find(
        (p: any) => p.id !== state.signal.userId
      );
    },
    participation: (state) => {
      return state.signal.meeting?.participations?.find(
        (p: any) => p.userId === state.signal.userId
      );
    },
    remoteUserHasAudio(state) {
      const userAudio =
        state.agora?.remoteAudioTracks?.[0] ?? (null as IRemoteAudioTrack);

      return (
        userAudio != null &&
        userAudio != undefined &&
        userAudio.getMediaStreamTrack()?.enabled &&
        !userAudio.getMediaStreamTrack()?.muted
      );
    },
    remoteUserHasVideo(state) {
      const userVideo =
        state.agora?.remoteVideoTracks?.[0] ?? (null as IRemoteVideoTrack);

      return (
        userVideo != null &&
        userVideo != undefined &&
        userVideo.getMediaStreamTrack()?.enabled
      );
    },
    lastCancelTime(state) {
      if (state.selectedMeeting == null) return "";

      return formatDateAndTime(subDays(state.selectedMeeting.startDate, 1));
    },
    isItLateForCancel(state) {
      if (state.selectedMeeting == null) return false;

      return isAfter(
        new Date().toISOString(),
        subDays(state.selectedMeeting.startDate, 1)
      );
    },
    isCancellationAvailable(state) {
      return (
        state.signal.user.userType == "Client" &&
        state.selectedMeeting != null &&
        isBefore(new Date().toISOString(), state.selectedMeeting.startDate)
      );
    },
    meetingCountById: (state) => (otherUserId) => {
      return state.meetingCounts[otherUserId] || 0;
    },
  },
  actions: {
    // General actions
    changeViewState(viewState: string) {
      this.viewState = viewState;
    },
    // Agora related actions
    async agoraInitialize(
      token: string,
      channel: string,
      uid: string | number = null
    ) {
      if (this.agora != null && this.agora?.getChannel() === channel) {
        this.agora.setActiveDevices(this.activeDevices);
        return;
      }

      const nuxtApp = useNuxtApp();
      const agoraAppId = useRuntimeConfig().public.agoraAppId;
      const agora = Object.create(await import("agora-rtc-sdk-ng"));
      this.agora = new HeltiaAgora(
        agora,
        agoraAppId,
        channel,
        token,
        nuxtApp.$appInsights,
        uid,
        this.activeDevices
      );
      await this.agora.getDevices();

      this.agora.onActiveDeviceStateChange((searching, devices) => {
        this.isActiveDeviceSearching = searching;

        if (searching == false) {
          this.activeDevices = devices;
        }
      });
    },
    // Signal related actions
    async signalInitialize(meetingId: string = null) {
      this.signal.meetingId = meetingId;
      const authStore = useAuthStore();
      if (authStore.getIsAuthenticated) {
        this.signal.userId = authStore.user?.id;
      }
      const signalRBaseUrl = useRuntimeConfig().public.signalrBaseUrl;

      this.signalR = new HeltiaSignalR(signalRBaseUrl, "callhub");
      this.signalR
        .on("otc_received", (otc) => {
          this.signal.otc = otc;
        })
        .on("countdown_received", (countdown) => {
          this.signal.countdown = countdown;
        })
        .on("bearer_received", (bearer) => this.signalOnBearerReceived(bearer))
        .on("meeting_state_changed", (meeting) => {
          if (meeting != null && meeting != undefined) {
            var meetingUser = meeting.participants?.find(
              (p: any) => p.id == this.signal.userId
            );
            var meetingUserIndex = meeting.participants.findIndex(
              (p: any) => p.id == this.signal.userId
            );

            this.signal.user = { ...meetingUser, agoraIndex: meetingUserIndex };
            var userWebParticipation = meeting.participations?.find(
              (p: any) =>
                p.userId == this.signal.userId &&
                p.connectionId === this.signalR.connectionId
            );

            var otherConnectionsFromWeb = meeting.participations?.find(
              (p: any) =>
                p.userId == this.signal.userId &&
                p.connectionId != this.signalR.connectionId &&
                p.source == "web"
            );
            var otherConnectionsFromApp = meeting.participations?.find(
              (p: any) =>
                p.userId == this.signal.userId &&
                p.connectionId != this.signalR.connectionId &&
                p.source == "app" &&
                p.viewState != "inLobby"
            );

            if (userWebParticipation != null) {
              if (
                this.viewState == "alreadyInMeeting" &&
                (otherConnectionsFromWeb || otherConnectionsFromApp)
              ) {
                // do nothing
              } else {
                this.signal.user["meetingToken"] =
                  userWebParticipation.meetingToken;
                this.signal.user["viewState"] = userWebParticipation.viewState;
                this.changeViewState(userWebParticipation.viewState);
              }
            }

            const isMeetingChanged = !isEqual(this.signal.meeting, meeting);
            if (isMeetingChanged && authStore.getAccessToken() != null) {
              this.signalMeetingChanged(meeting);
            }
          }

          this.signal.meeting = meeting == undefined ? null : meeting;
        })
        .on("already_in_meeting", () => {
          this.changeViewState("alreadyInMeeting");
        });

      await this.signalR.connect();
      if (authStore.getIsAuthenticated) {
        this.signalR.call("ChangeViewStateV2", "inLobby", meetingId, "web");
      }
    },
    async signalOnBearerReceived(bearer: string) {
      const authenticationResponse = JSON.parse(
        bearer
      ) as AuthenticationResponse;

      const authStore = useAuthStore();
      await authStore.authenticationResponseSignin(authenticationResponse);

      this.onOtcAuthenticated?.call(this);
    },
    async signalMeetingChanged(meeting: any) {
      if (meeting?.state === "approved") {
        this.approved = true;
      }
    },
    // Permission related actions
    async permissionCheck() {
      this.videoCallPermissions.isMicrophonePermissionInProgress = true;
      this.videoCallPermissions.isCameraPermissionInProgress = true;

      return new Promise<Boolean>((resolve, reject) => {
        Promise.all([
          navigator.permissions.query({ name: "camera" as PermissionName }),
          navigator.permissions.query({ name: "microphone" as PermissionName }),
        ])
          .then(([cameraPermission, microphonePermission]) => {
            this.videoCallPermissions.states.camera = cameraPermission.state;
            this.videoCallPermissions.isCameraPermissionGranted =
              cameraPermission.state === "granted";
            this.videoCallPermissions.isCameraPermissionInProgress = false;

            this.videoCallPermissions.states.microphone =
              microphonePermission.state;
            this.videoCallPermissions.isMicrophonePermissionGranted =
              microphonePermission.state === "granted";
            this.videoCallPermissions.isMicrophonePermissionInProgress = false;

            resolve(
              this.videoCallPermissions.isCameraPermissionGranted &&
                this.videoCallPermissions.isMicrophonePermissionGranted
            );
          })
          .catch((error) => {
            console.error({ error });
            reject(false);
          });
      });
    },
    async permissionRequestMicrophone() {
      this.videoCallPermissions.isMicrophonePermissionInProgress = true;
      return new Promise<void>(async (resolve, reject) => {
        if (this.videoCallPermissions.states.microphone === "granted")
          resolve();
        try {
          const result = await navigator.mediaDevices.getUserMedia({
            audio: true,
          });
          result.getAudioTracks().forEach((track) => track.stop());
          this.videoCallPermissions.states.microphone = "granted";
          this.videoCallPermissions.isMicrophonePermissionGranted = true;
        } catch (error) {
          this.videoCallPermissions.states.microphone = "denied";
          this.videoCallPermissions.isMicrophonePermissionGranted = false;
        } finally {
          this.videoCallPermissions.isMicrophonePermissionInProgress = false;
          resolve();
        }
      });
    },
    async permissionRequestCamera() {
      this.videoCallPermissions.isCameraPermissionInProgress = true;
      return new Promise<void>(async (resolve, reject) => {
        if (this.videoCallPermissions.states.camera === "granted") resolve();
        try {
          const result = await navigator.mediaDevices.getUserMedia({
            video: true,
          });
          result.getVideoTracks().forEach((track) => track.stop());
          this.videoCallPermissions.states.camera = "granted";
          this.videoCallPermissions.isCameraPermissionGranted = true;
        } catch (error) {
          this.videoCallPermissions.states.camera = "denied";
          this.videoCallPermissions.isCameraPermissionGranted = false;
        } finally {
          this.videoCallPermissions.isCameraPermissionInProgress = false;
          resolve();
        }
      });
    },
    async permissionRequestAll() {
      if (
        this.videoCallPermissions.states.microphone === "granted" &&
        this.videoCallPermissions.states.camera === "granted"
      )
        return;
      this.videoCallPermissions.isMicrophonePermissionInProgress = true;
      this.videoCallPermissions.isCameraPermissionInProgress = true;
      const result = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: true,
      });
      result.getTracks().forEach((track) => track.stop());
      this.videoCallPermissions.states.microphone = "granted";
      this.videoCallPermissions.states.camera = "granted";
      this.videoCallPermissions.isMicrophonePermissionGranted = true;
      this.videoCallPermissions.isCameraPermissionGranted = true;
      this.videoCallPermissions.isMicrophonePermissionInProgress = false;
      this.videoCallPermissions.isCameraPermissionInProgress = false;
    },
    switchAudioState(state: boolean) {
      this.agora?.switchAudioState(state);
    },
    switchVideoState(state: boolean) {
      this.agora?.switchVideoState(state);
    },
    // Meeting related actions
    async getMeetingsFromUserJourney() {
      const userStore = useUserStore();
      var meetings = userStore.journey?.meetings;
      if (meetings == null) {
        await userStore.retrieveJourney();
        meetings = userStore.journey?.meetings;
      }
      return meetings;
    },
    async setSelectedMeeting(meetingId: string, updateMeetingState = true) {
      const userStore = useUserStore();
      var meetings = userStore.journey?.meetings;
      if (meetings == null) {
        const authStore = useAuthStore();
        if (authStore.getIsAuthenticated) {
          try {
            await userStore.retrieveJourney();
            meetings = userStore.journey?.meetings;
          } catch {}
        }
      }
      this.selectedMeeting = meetings?.find((m) => m.id == meetingId);

      if (this.selectedMeeting && updateMeetingState) {
        if (
          isAfter(new Date().toISOString(), this.selectedMeeting.expiryDate)
        ) {
          this.changeViewState("expired");
        }

        if (!this.selectedMeeting.hasSufficentCredits) {
          this.changeViewState("insufficientCredits");
        }
      }
    },
    clearSelectedMeeting() {
      this.selectedMeeting = null;
    },

    async cancelMeeting() {
      this.cancelMeetingForm.inProgress = true;
      const deleteRequest = new MeetingDeleteRequest();
      deleteRequest.meetingId = this.selectedMeeting.id;
      deleteRequest.reason = this.cancelMeetingForm.cancelReason;

      const deletedMeeting = await deleteMeeting(deleteRequest);
      this.cancelMeetingForm.inProgress = false;

      this.trackCancelMeetingEvent();
      return deletedMeeting;
    },
    trackCancelMeetingEvent() {
      const tracking = Tracking.getInstance();

      const meeting = this.selectedMeeting;

      const client = meeting.participants?.find(
        (p: any) => p.userType === "Client"
      );
      const provider = meeting.participants?.find(
        (p: any) => p.userType !== "Client"
      );

      const meetingStartDate = new Date(meeting.startDate);
      tracking.meeting("Deleted", {
        ClientEmail: client.email,
        ProviderEMail: provider.email,
        "Scheduled Date": meeting.startDate,
        "Scheduled Day": new Date(
          meetingStartDate?.getFullYear() || 2000,
          meetingStartDate?.getMonth() + 1 || 1,
          meetingStartDate?.getDate() || 1
        ),
        "Scheduled Hour": meetingStartDate?.getHours() || 0,
        "Scheduled Minute": meetingStartDate?.getMinutes() || 0,
        "Meeting Type": meeting.type,
        "Meeting Id": meeting.id,
        Source: "lobby",
        ProviderType: provider.userType,
        Reason: this.cancelMeetingForm.cancelReason,
        LateCancel: this.isItLateForCancel,
      });
    },
    updateMeetingSource(source: MeetingSource) {
      this.meetingSource = source;
    },
    async getMeetingCountWithId(otherUserId: string) {
      const count = await getMeetingCount(otherUserId, true);
      this.meetingCounts[otherUserId] = count;
    },
    clearSignal() {
      this.signal = {
        status: false,
        otc: null,
        countdown: null,
        bearer: null,
        userId: null,
        meetingId: null,
        meeting: null,
        user: null,
      };
    },
  },
});
