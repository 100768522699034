export class VideoCallPermissions {
  states: States;

  isMicrophonePermissionInProgress: boolean = false;
  isCameraPermissionInProgress: boolean = false;

  isMicrophonePermissionGranted: boolean = false;
  isCameraPermissionGranted: boolean = false;

  constructor() {
    this.states = Object.assign({}, new States());
  }
}

class States {
  camera: PermissionState = "prompt";
  microphone: PermissionState = "prompt";
}
